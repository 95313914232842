import React from 'react'
import Head from '../components/head'

export default function NotFound() {
    return (
        <div>
            <Head title='No encontrado' />
            Not Found
        </div>
    )
}
